import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  id: "video-landing-page-form-wrapper",
  class: "vid-form vid-space"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["resource-gated-video resource-video eclipse-version", [$setup.ungatedClass, { playing: $setup.videoPlaying }]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode(" The Main Video Content "),
        _createVNode($setup["videoContent"], {
          "custom-data": $setup.data,
          "widget-data": $props.widgetData
        }, {
          "no-gate-video": _withCtx(() => [
            _createVNode($setup["noGateVideo"], {
              onPlayclicked: _cache[0] || (_cache[0] = $event => $setup.btnClick = $event)
            })
          ]),
          "resource-form": _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["resourceForm"], {
                "form-settings-key": $props.formSettingsKey,
                "widget-data": $props.widgetData,
                "disable-form-heading": true,
                "custom-data": $props.customData
              }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["custom-data", "widget-data"])
      ])
    ])
  ], 2 /* CLASS */))
}